.image {
    display: block;
    margin: 32px 0;
    position: relative;
    text-decoration: none;
    border-radius: 10px;

    &:hover {
        text-decoration: none;
    }

    img {
        border-radius: 10px;
        display: block;
        max-width: 100%;
    }

    .imageCaption {
        font-size: 12px;
        color: #555555;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

    &.hasZoom .imageCaption {
        padding-left: 32px;
    }
}

.imageZoom {
    align-items: center;
    background-color: #ffffff;
    bottom: 10px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, .2);
    border-radius: 100%;
    color: #232323;
    cursor: pointer;
    display: flex;
    left: -10px;
    height: 36px;
    justify-content: center;
    position: absolute;
    text-decoration: none;
    width: 36px;

    &:hover {
        color: #232323;
        text-decoration: none;
    }
}
