@import './RbKit/config';

.main {
    height: calc(100vh - 4rem);
    margin-top: 4rem;
    overflow: auto;
    padding: 2rem 1rem;
    transition: margin 200ms ease-out;
    @include portrait {
        padding: 2rem;
    }
    @include landscape {
        margin-left: 290px;

        &.isCollapsed {
            margin-left: 0;
        }
    }

    > div {
        margin: 0 auto;
        max-width: 1200px;
    }
}

.mainFront {
    background-color: #ffffff;
    font-family: 'Muli', sans-serif;
    color: #151515;
    padding-top: 2rem;
    height: calc(100vh - 100px);
    overflow: auto;

    h1, h2, h3, h4, h5, b, strong {
        font-family: 'VAGRundschriftD', sans-serif;
    }

    h1 {
        font-size: 2rem;
        margin: 0 0 1.5rem 0;
    }

    h2, h3, h4, h5 {
        font-size: 1.5rem;
        margin: 0 0 1rem 0;
    }

    a {
        color: $colorRocheBlue;
        text-decoration: underline;
    }

    p, ul, ol {
        font-size: 20px;
        line-height: 1.6;
        margin-bottom: 32px;
    }

    p + ul, p + ol {
        margin-top: -24px;
    }

    p + h2 {
        padding-top: 1rem;
    }

    ul {
        list-style: none;

        li:before {
            content: '\2022';
            color: $colorRocheBlue;
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
            top: 2px;
            position: relative;
        }
    }

    .image {
        display: block;
        margin-bottom: 32px;
        position: relative;
        text-decoration: none;
        
        &:hover {
            text-decoration: none;
        }
    
        img {
            display: block;
            max-width: 100%;
        }
    
        .imageCaption {
            font-size: 12px;
            color: #555555;
            text-decoration: none;
    
            &:hover {
                text-decoration: none;
            }
        }
    }

    table {
        border-radius: 10px;
        margin-bottom: 32px;

        thead tr:first-child {
            td:first-child, th:first-child {
                border-top-left-radius: 10px;
            }
            td:last-child, th:last-child {
                border-top-right-radius: 10px;
            }
        }

        tbody tr:last-child {
            td:first-child, th:first-child {
                border-bottom-left-radius: 10px;
            }
            td:last-child, th:last-child {
                border-bottom-right-radius: 10px;
            }
        }

        tbody tr:nth-child(odd) td {
            background-color: #fafafa;
        }
        
        tbody tr:hover {
            td {
                background-color: $colorBlue5;
            }
        }
    }
}
