.clinical {
    height: 1420px;
    width: 100%;
    margin: 32px auto;
    border: 0px;
}

.placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;

    svg {
        font-size: 50px;
    }
}
