@import '../../../../RbKit/config';

.thumb {
    order: 1;

    a {
        margin: 5px 0 0 0;
    }

    > div {
        padding-left: 0 !important;
        padding-right: 0 !important;
        @include portrait {
            margin: 0 0 32px 0 !important;
        }

        picture img {
            width: 100% !important;
        }
    }
}

.content {
    order: 2;
    flex: 1;
    @include portrait {
        margin: 0 0 0 2rem;
    }
}

.segment {
    display: flex;
    flex-direction: column;
    @include portrait {
        flex-direction: row;
    }

    &.right {
        .thumb {
            order: 2;
        }
        .content {
            order: 1;
            @include portrait {
                margin: 0 2rem 0 0;
            }
        }
    }
}
