button.button,
a.button {
    background-color: #ec008c;
    border: 0;
    border-radius: 30px;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    line-height: 1.6;
    min-width: 140px;
    padding: 7px 20px;
    text-align: center;
    text-decoration: none;
    transition:
        background-color 150ms linear,
        color 150ms linear;
    vertical-align: middle;

    svg {
        font-size: 13px;
        margin-right: .5rem;
        line-height: 1;
        position: relative;
        top: -2px;
    }

    &:hover {
        background-color: #b9006e;
        color: #ffffff;
    }
}

body.menu-open th {
    position: static !important;
}

iframe.clinical {
    width: 100%;
    margin: 32px auto;
    border: 0;
}
