@import '../../../RbKit/config';

.location {
    display: flex;
    align-items: flex-start;
    margin-bottom: 3rem;
    flex-wrap: wrap;

    > div {
        flex-basis: 100%;
        @include portrait {
            flex-basis: 50%;
        }
        @include landscape {
            flex-basis: 33.33334%;
        }
    }

    .address {
        font-size: 16px;
        line-height: 1.4;
        margin-bottom: 1rem;
        margin-right: 1rem;
        padding-right: 1rem;
        border-right: solid 1px #e5e5e5;
        position: relative;
        padding-left: 3rem;

        span {
            left: 0;
            position: absolute;
        }
    }

    > div:last-child .address {
        border: 0;
        margin-right: 0;
        padding-right: 0;
    }
}

.indicator {
    color: $colorRocheBlue;
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
}

.icon {
    color: #000000;
    font-size: 1rem;
    margin: 0;

    &:hover {
        color: $colorRocheBlue;
    }
}

.badge {
    background: $colorRocheBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
}
