@import '../../config';

.base {
    border: solid 1px $colorGrey10;
    border-collapse: separate;
    border-spacing: 0;
    padding: 0;
    width: 100%;

    tr {
        td, th {
            color: $colorGrey90Alpha;
            border-bottom: solid 1px $colorGrey10;
            font-size: .875rem;
            line-height: 1.25rem;
            padding: .75rem 1rem;
            transition: background 150ms linear;

            &:first-child {
                padding-left: 1.5rem;
                @include mobileOnly {
                    padding-left: 1rem;
                }
            }
            &:last-child {
                padding-right: 1rem;
                @include mobileOnly {
                    padding-right: 1rem;
                }
            }

            &.collapsing {
                @include portrait {
                    white-space: nowrap;
                    width: 1px;
                }
            }

            &.centerAlign { text-align: center; }
            &.leftAlign { text-align: left; }
            &.rightAlign { text-align: right; }

            .sortIcon {
                cursor: pointer;
                margin-left: 10px;
            }

            .collapseIcon {
                align-items: center;
                color: $colorGrey50Alpha;
                display: flex;
                justify-content: center;
                height: 2rem;
                margin-right: -.5rem;
                transition: color 150ms linear, transform 150ms linear;
                width: 2rem;
                @include portrait {
                    display: none;
                }
            }
        }

        th {
            background: $colorWhite;
            height: 3rem;
            padding-bottom: .5rem;
            padding-top: .5rem;
            position: sticky;
            top: -2rem;
            z-index: 100;
            @include mobileOnly {
                display: none;
            }
        }

        td {
            font-size: 1rem;
            height: 4rem;
            @include mobileOnly {
                border-bottom: 0;
                display: none;
                height: auto;

                &.primary {
                    display: flex !important;
                    // align-items: center;
                    border-bottom: solid 1px $colorGrey10;
                    justify-content: space-between;
                }

                > div:first-child:before {
                    color: $colorGrey60Alpha;
                    content: attr(data-name);
                    display: block;
                    font-size: .75rem;
                }

                &:last-child {
                    border-bottom: solid 1px $colorGrey10;
                }

                &.actions {
                    > div {
                        position: absolute;
                        top: 1rem;
                        right: 2.5rem;
                    }
                }
            }
        }

        &.expanded {
            @include mobileOnly {
                display: block;
                position: relative;

                td {
                    display: block;
                    border-bottom: 0;

                    &:last-child {
                        border-bottom: solid 1px $colorGrey10;
                    }

                    .collapseIcon {
                        color: $colorBlack;
                        transform: rotate(90deg);
                    }
                }
            }
        }

        &:first-child th {
            border-top: 0;
        }

        &:last-child td {
            border-bottom: 0;
        }
    }

    tbody tr:hover {
        td {
            background-color: $colorBlue5;
        }
    }

    &.fluid {
        border: 0;
        margin: 0 -2rem -2rem -2rem;
        width: calc(100% + 4rem);
    }
}

.actionsContainer {
    align-items: center;
    border-bottom: solid 2px $colorGrey10;
    display: flex;
    justify-content: flex-end;
    padding: 0rem 1.5rem;
    margin: -2rem -2rem 0 -2rem;

    .searchContainer {
        flex: 1;

        input {
            padding-top: 0rem;
        }
    }

    .searchContainer + .filterContainer {
        border-left: solid 1px $colorGrey10;
        padding-left: 1rem;
    }

    .filterContainer {
        position: relative;
    }

    .filterInnerContainer {
        min-width: 320px;
        position: absolute;
        right: 1rem;
        top: 1.25rem;
        z-index: 101;

        .filterButtons {
            border-top: solid 1px $colorGrey10;
            display: flex;
            justify-content: flex-end;
            margin: 1rem -1rem 0 -1rem;
            padding: 1rem 1rem 0 1rem;

            button {
                margin-left: 1rem;
            }
        }
    }
}

.pagination {
    display: flex;

    a {
        align-items: center;
        background:transparent;
        border: solid 1px transparent;
        display: inline-flex;
        font-size: .875rem;
        justify-content: center;
        line-height: 1.25;
        min-width: calc(1rem + 1.125rem + 2px);
        padding: .5625rem .625rem;

        &.isDisabled {
            color: $colorGrey90Alpha;
            cursor: default;
        }

        &.isActive {
            color: $colorRocheBlue;
        }

        &:hover {
            background-color: #f2f7fD;
        }
    }
}

.notResponsive {
    tr {
        td, th {
            border-bottom: solid 1px $colorGrey10;
            
            &:first-child {
                padding-left: 1.5rem;
            }
            &:last-child {
                padding-right: 1rem;
            }

            &.collapsing {
                white-space: nowrap;
                width: 1px;
            }

            .collapseIcon {
                display: none;
            }
        }

        th {
            display: table-cell;
        }

        td {

            display: table-cell;
            font-size: 1rem;
            height: 4rem;
        }

        &.expanded {
            display: none;
        }
    }
}

.small {
    border: 0;

    tbody tr td {
        padding: 0 !important;
        font-size: 14px !important;
        height: 26px !important;

        > div {
            padding-right: 1rem;
        }
    }
}
