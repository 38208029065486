@import '../../../RbKit/config';

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1300px;
    padding-left: 1rem;
    padding-right: 1rem;
    width: 100%;

    svg {
        cursor: pointer;
    }

    &.small {
        max-width: 1040px;
        @include portrait {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
