@import '../../../RbKit/config';

.footer {
    padding-bottom: 50px;
    padding-top: 100px;
}

.innerContainer {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;

    .col {
        flex-basis: 50%;
        margin-bottom: 2rem;
        padding-right: 1rem;
        @include portrait {
            flex-basis: 25%;
            margin-bottom: 0;
            padding-right: 0;
        }

        b, a {
            display: block;
            font-size: 16px;
            line-height: 1.4;
            margin-bottom: .75rem;
            text-decoration: none;
            color: #151515;
            @include portrait {
                font-size: 18px;
            }

            svg {
                font-size: 14px;
                margin-right: .75rem;
            }
        }

        a:hover {
            color: #151515;
            text-decoration: underline;
        }

        &.social a {
            display: inline-block;
            font-size: 2rem;
            margin-right: 0;
            @include portrait {
                margin-right: .5rem;
            }

            svg {
                font-size: 2rem;
            }

            &:hover {
                color: $colorRocheBlue;
            }
        }
    }
}
