@import '../../../RbKit/config';

.base {
    display: flex;
    align-items: center;
    min-height: 40px;
    position: relative;
    width: 120px;

    .bar {
        background-color: $colorGrey10;
        border-radius: 3px;
        height: 5px;
        flex: 1;
        width: 100%;

        > div {
            background-color: $colorRocheBlue;
            height: 5px;
            border-radius: 3px;
        }
    }

    .average {
        background: #000;
        border-radius: 3px;
        width: 3px;
        height: 1rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        z-index: 3;
    }

    .tooltip {
        @include elevate(6);
        bottom: 0;
        background-color: $colorWhite;
        position: absolute;
        padding: 1rem .75rem .5rem .75rem;
        opacity: 0;
        transition: opacity 200ms ease-in-out, bottom 200ms ease-in-out, visibility 0ms ease-in-out 200ms;
        visibility: hidden;
        pointer-events: none;
        width: 200px;
        z-index: 1000;

        p {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            margin-bottom: .5rem;
            padding: 0 1rem;
        }
    }

    &:hover {
        .tooltip {
            bottom: 30px;
            opacity: 1;
            transition-delay: 0s;
            visibility: visible;
        }
    }
}
