@import '../../../RbKit/config';

.location {
    align-items: flex-start;
    margin-bottom: 3rem;
    @include portrait {
        display: flex;
    }

    .address {
        font-size: 20px;
        line-height: 1.6;
        margin-bottom: 1rem;
        @include portrait {
            margin-bottom: 0;
            margin-right: 2rem;
        }
    }

    .map {
        flex: 1;
        border-radius: 12px;
        height: 180px;
        overflow: hidden;
        @include portrait {
            height: 250px;
        }
    }
}

.indicator {
    color: $colorRocheBlue;
    font-size: 1.5rem;
    margin: 0 0 1rem 0;
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.icon {
    color: #000000;
    font-size: 1rem;
    margin: 0;

    &:hover {
        color: $colorRocheBlue;
    }
}

.bar {
    display: none !important;
    @include portrait {
        display: table-cell !important;
    }
}
