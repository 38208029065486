@import '../../RbKit/config';

.container {
    align-items: center;
    background: $colorWhite;
    box-shadow: 0 5px 12px rgba(13, 31, 51, .12);
    display: flex;
    height: 4rem;
    justify-content: center;
    padding: 0 1rem;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
    @include portrait {
        padding: 0 1.5rem;
    }

    .innerContainer {
        align-items: center;
        display: flex;
        height: 4rem;
        justify-content: center;
        width: 100%;

        .title {
            flex: 1;

            h1 {
                color: $colorRocheBlue;
                margin: 0;
            }
        }
    }
}

.account {
    padding-right: 1rem;
    @include portrait {
        padding-right: 1.5rem;
    }

    .avatar {
        @include fullCenter;
        background-color: $colorBlue40;
        border-radius: 100%;
        cursor: pointer;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1;
        height: 2.5rem;
        width: 2.5rem;
    }
}

.logo {
    height: 32px;
}

.hamburger {
    color: #ffffff;
    flex: 1;
    font-size: 1.5rem;
    margin-right: 1rem;

    svg {
        cursor: pointer;
    }
}

.module {
    color: #ffffff !important;
    font-size: 1.25rem;
    font-weight: 700;
    left: 50%;
    line-height: 1;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 50%;
    transform: translate(-50%, -50%);
}

.isFront {
    background: $colorRocheBlue;

    .logo svg {
        color: #ffffff;
    }
}

.subBar {
    align-items: center;
    background: $colorWhite;
    box-shadow: 0 5px 12px rgba(13, 31, 51, .12);
    display: flex;
    height: 3rem;
    font-weight: 600;
    justify-content: center;
    padding: 0 1rem;
    position: fixed;
    top: 4rem;
    width: 100%;
    text-transform: uppercase;
    z-index: 99;
    @include portrait {
        padding: 0 1.5rem;
    }

    .subBarBar {
        svg {
            margin-left: .5rem;
        }
    }

    .subList {
        background-color: $colorWhite;
        box-shadow: 0 0 12px rgba(13, 31, 51, .12);
        position: absolute;
        left: 50%;
        margin-top: -30px;
        max-height: calc(90vh - 100px);
        overflow: auto;
        opacity: 0;
        pointer-events: none;
        transform: translateX(-50%);
        top: 3rem;
        transition: opacity 150ms ease-out, margin 150ms ease-out;
        width: 320px;
    }

    &:hover .subList {
        opacity: 1;
        margin-top: 0;
        pointer-events: inherit;
    }
}
