@import '../../../RbKit/config';

.searchInput {
    background-color: #ffffff;
    border-radius: 50px;
    box-shadow: 0 0 12px rgba(0, 0 ,0 , .2);
    height: 54px;
    margin-bottom: 3rem;
    margin-top: 1rem;
    position: relative;

    .input {
        border: 0;
        background-color: transparent;
        color: #151515;
        font-family: 'Muli', sans-serif;
        font-size: 18px;
        height: 54px;
        margin: 0;
        padding: 0 4.5rem 0 2rem;
    }

    .location {
        cursor: pointer;
        font-size: 12px;
        position: absolute;
        right: 4rem;
        transform: translateY(-50%);
        top: 50%;
    }

    > svg {
        cursor: pointer;
        font-size: 24px;
        position: absolute;
        right: 2rem;
        transform: translateY(-50%);
        top: 50%;
    }
}

.hospitals {
    display: flex;
    flex-direction: column;
    @include landscape {
        flex-direction: row;
    }

    .data {
        flex: 1;
        order: 2;
        @include landscape {
            order: 1;
        }
    }

    .selected {
        order: 1;
        margin-bottom: 1rem;
        @include landscape {
            margin-bottom: 0;
            margin-left: 2rem;
            order: 2;
            max-width: 300px;
        }

        p {
            font-size: 12px;
            line-height: 1.4;
            padding: 0 1rem;
        }

        table tbody tr td {
            background-color: #ffffff !important;
            padding: .75rem 1rem !important;
            font-size: 14px !important;
            height: auto !important;
        }
    }
}
