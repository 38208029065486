@import '../../../RbKit/config';

.container {
    background-color: #ffffff;
    height: 100px;
    position: fixed;
    width: 100%;
    z-index: 99;

    .innerContainer {
        align-items: center;
        display: flex;
        height: 100px;
        justify-content: space-between;
        position: relative;
        z-index: 2;
    }

    .menuOverlay {
        background: #f7f7f9;
        position: fixed;
        height: 0;
        transition: height 250ms ease-in-out;
        top: 0;
        overflow: hidden;
        width: 100%;
        z-index: 1;

        .inner {
            position: relative;
            height: 100vh;
            width: 100%;
        }

        .navigation {
            z-index: 1;
            padding: 0 2rem;
            position: absolute;
            top: 164px;
            left: 0;
            width: 100%;
            @include portrait {
                left: 50%;
                transform: translateX(-100%);
                width: 50vw;
                max-width: 618px;
            }

            a {
                display: block;
                font-size: 24px;
                font-family: 'VAGRundschriftD', sans-serif;
                font-weight: bold;
                margin-bottom: 1.5rem;
                color: #151515;

                &:hover {
                    color: $colorRocheBlue;
                }
            }
        }

        .search {
            display: none;
            background: #ffffff;
            z-index: 1;
            align-items: center;
            height: 100vh;
            padding: 0 2rem;
            position: absolute;
            top: 0;
            right: 0;
            width: 50vw;
            @include landscape {
                display: flex;
            }

            > div {
                width: 100%;
                @include landscape {
                    max-width: 586px;
                }
            }
        }

        &.open {
            height: 100vh;
        }
    }

    .logo {
        display: block;
        height: 67px;
        width: 62px;
        overflow: hidden;
        @include portrait {
            width: auto;
        }

        img {
            height: 100%;
        }
    }

    .right {
        align-items: center;
        display: flex;

        .search {
            color: #151515;
            font-size: 1.5rem;
            margin-right: 1.5rem;
        }

        .support {
            background-color: $colorRocheBlue;
            border-radius: 30px;
            color: #ffffff;
            display: none;
            font-size: 20px;
            line-height: 1.6;
            margin-right: 1rem;
            min-width: 140px;
            padding: 7px 20px;
            transition: background 150ms linear;
            width: 100%;
            @include portrait {
                display: inline-block;
            }

            svg {
                font-size: 13px;
                margin-right: .5rem;
                line-height: 1;
                position: relative;
                top: -2px;
            }

            &:hover {
                background-color: $colorHover;
            }
        }
    }

    .bars {
        align-items: center;
        cursor: pointer;
        display: flex;
        min-width: 140px;
        padding: 7px 20px;

        > div {
            margin-right: 1rem;
            margin-top: -4px;
        }

        &:after {
            color: #151515;
            content: 'Menu';
            font-size: 20px;
            line-height: 1.6;
        }

        span {
            background: #151515;
            border-radius: 3px;
            display: block;
            height: 3px;
            margin-top: 4px;
            transition: all 250ms ease;
            width: 1.5rem;
        }

        &.open {
            &:after {
                content: 'Sluiten';
            }

            span {
                &:nth-child(1) {
                    transform: rotate(45deg) translate(4px, 4px);
                }
                &:nth-child(2) {
                    opacity: 0;
                    width: 0;
                }
                &:nth-child(3) {
                    transform: rotate(-45deg) translate(5px,-6px);
                }
            }
        }
    }
}

.push {
    height: 100px;
}
